import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Dialog, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';

const JobApplicationPage = () => {
  const { title } = useParams();  //  job title
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    currentCompany: '',
    location: '',
    linkedin: '',
    pronouns: '',
    resume: ''
  });
  const navigate = useNavigate();
  const [warningDialogOpen, setWarningDialogOpen] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    setWarningDialogOpen(true);
  };

  return (
    <Box sx={{ padding: '25px', backgroundColor: '#f8f9fa' }}>
      {/* Top bar: Application title on the left, Exit button on the right */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          Application: {title}
        </Typography>
        <Button onClick={() => navigate('/careers')} sx={{fontWeight: 'bold', textTransform: 'none', color: '#1976d2' }}>
          Exit to job board
        </Button>
      </Box>
      
      <Box sx={{ maxWidth: '450px', margin: 'auto' }}>
      <form onSubmit={handleSubmit} style={{ marginTop: '20px' }}>
  {/* First Name */}
  <Typography variant="body2" sx={{ marginBottom: '5px' }}>First name*</Typography>
  <TextField
    name="firstName"
    value={formData.firstName}
    onChange={handleChange}
    fullWidth
    required
    placeholder="First name"
    sx={{ marginBottom: '20px' }}
  />

  {/* Last Name */}
  <Typography variant="body2" sx={{ marginBottom: '5px' }}>Last name*</Typography>
  <TextField
    name="lastName"
    value={formData.lastName}
    onChange={handleChange}
    fullWidth
    required
    placeholder="Last name"
    sx={{ marginBottom: '20px' }}
  />

  {/* Email */}
  <Typography variant="body2" sx={{ marginBottom: '5px' }}>Email*</Typography>
  <TextField
    name="email"
    value={formData.email}
    onChange={handleChange}
    fullWidth
    required
    placeholder="Email"
    sx={{ marginBottom: '20px' }}
  />

  {/* Current Company */}
  <Typography variant="body2" sx={{ marginBottom: '5px' }}>Current Company*</Typography>
  <TextField
    name="currentCompany"
    value={formData.currentCompany}
    onChange={handleChange}
    fullWidth
    required
    placeholder="Current company"
    sx={{ marginBottom: '20px' }}
  />

  {/* Phone Number */}
  <Typography variant="body2" sx={{ marginBottom: '5px' }}>Phone number*</Typography>
  <TextField
    name="phone"
    value={formData.phone}
    onChange={handleChange}
    fullWidth
    required
    placeholder="Phone number"
    sx={{ marginBottom: '20px' }}
  />

  {/* Location */}
  <Typography variant="body2" sx={{ marginBottom: '5px' }}>Location (city only)*</Typography>
  <TextField
    name="location"
    value={formData.location}
    onChange={handleChange}
    fullWidth
    required
    placeholder="Location (city only)"
    sx={{ marginBottom: '20px' }}
  />

  {/* LinkedIn Link */}
  <Typography variant="body2" sx={{ marginBottom: '5px' }}>LinkedIn Link*</Typography>
  <TextField
    name="linkedin"
    value={formData.linkedin}
    onChange={handleChange}
    fullWidth
    required
    placeholder="LinkedIn Link"
    sx={{ marginBottom: '20px' }}
  />

  {/* Resume Upload */}
  <Typography variant="body2" sx={{ marginBottom: '5px' }}>Resume*</Typography>
  <Box sx={{ border: '2px dashed #ccc', padding: '30px', textAlign: 'center', marginBottom: '20px' }}>
    <Typography variant="body1" sx={{ marginBottom: '10px' }}>
      Drop or select (.doc / .docx / .pdf)
    </Typography>
    <TextField
      type="file"
      name="resume"
      onChange={(e) => setFormData({ ...formData, resume: e.target.files[0] })}
      fullWidth
      required
    />
  </Box>

  {/* Apply Button */}
  <Button type="submit" variant="contained" color="primary" fullWidth sx={{ marginTop: '20px' }}>
    Apply
  </Button>
</form>
</Box>

      {/* Warning Dialog */}
      <Dialog 
  open={warningDialogOpen} 
  onClose={() => setWarningDialogOpen(false)} 
  PaperProps={{
    sx: {
      background: 'linear-gradient(135deg, #f8f9fa, #d1d5db)',
      borderRadius: '20px',
      boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.1)',
      padding: '20px',
      maxWidth: '600px',  // wider than default for a modern look
    }
  }}
>
  <DialogContent>
    <DialogContentText 
      sx={{ 
        textAlign: 'center', 
        color: '#333', 
        fontSize: '1.1rem' 
      }}
    >
      <Typography 
        variant="h6" 
        sx={{ 
          marginBottom: '20px', 
          fontWeight: '600', 
          fontSize: '1.4rem', 
          lineHeight: '1.5',
          color: '#1a202c'
        }}
      >
        We’re Sorry!
      </Typography>
      <Typography 
        sx={{ 
          fontSize: '1.2rem', 
          lineHeight: '1.6',
          color: '#4a5568'
        }}
      >
        Due to high application volume, we are experiencing issues processing your submission. Please send your resume to{' '}
        <a href="mailto:contact@numeroalpha.com" 
        style={{ 
          color: '#1976d2', 
          textDecoration: 'none', 
          fontWeight: 'bold' }}
          >
          contact@numeroalpha.com
        </a>
      </Typography>
    </DialogContentText>
  </DialogContent>
  <DialogActions sx={{ justifyContent: 'center' }}>
    <Button 
      onClick={() => setWarningDialogOpen(false)} 
      sx={{ 
        background: 'linear-gradient(135deg, #4a90e2, #0062cc)', 
        color: '#fff',
        fontWeight: 'bold',
        padding: '10px 30px',
        borderRadius: '50px',
        textTransform: 'none',
        transition: 'transform 0.3s ease',
        '&:hover': {
          background: 'linear-gradient(135deg, #3a7bd5, #2859a6)',
          transform: 'scale(1.05)',  // slight zoom on hover
        }
      }}
    >
      Close
    </Button>
  </DialogActions>
</Dialog>


    </Box>
  );
};

export default JobApplicationPage;
