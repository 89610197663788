import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import logo from '../assets/logo.svg';

const Navbar = () => {
const [isMenuOpen, setIsMenuOpen] = useState(false);

const toggleMenu = () => {
setIsMenuOpen(!isMenuOpen);
};

const menuItems = [
{ text: 'Home', path: '/' },
{ text: 'Products', path: '/products' },
{ text: 'Careers', path: '/careers' },
{ text: 'About Us', path: '/about' },
{ text: 'Resources', path: '/resources' },
{ text: 'Contact Us', path: '/contact' },
];

return (
<AppBar position="sticky" color="default">
<Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
{/* Logo */}
<IconButton edge="start" color="inherit" aria-label="logo" component={NavLink} to="/">
<img src={logo} alt="NumeroAlpha Logo" style={{ height: '60px', width: '60px' }} />
</IconButton>

{/* Desktop Links */}
<Box
className="navbar-links-desktop"
sx={{
marginLeft: 'auto',
display: { xs: 'none', md: 'flex' },
gap: '20px',
}}
>
{menuItems.map((item) => (
<Button
key={item.text}
color="inherit"
component={NavLink}
to={item.path}
sx={{
textTransform: 'none',
fontWeight: 'bold',
'&.active': {
backgroundColor: '#333',
color: '#fff',
},
}}
>
{item.text}
</Button>
))}
</Box>

{/* Hamburger Icon for Mobile */}
<IconButton
edge="end"
color="inherit"
aria-label="menu"
onClick={toggleMenu}
sx={{ display: { xs: 'block', md: 'none' }, }}
>
<MenuIcon />
</IconButton>

{/* Mobile Drawer */}
<Drawer
anchor="left"
open={isMenuOpen}
onClose={toggleMenu}
PaperProps={{
sx: { height: '48vh' },
}}
>
<List sx={{ width: 400, padding:'15px' }}>
{menuItems.map((item) => (
<ListItem
button
key={item.text}
component={NavLink}
to={item.path}
onClick={toggleMenu}
sx={{
textTransform: 'none',
color: '#000',
'&.active': {
backgroundColor: '#333',
color: '#fff',
},
}}
>
<ListItemText primary={item.text} />
</ListItem>
))}
</List>
</Drawer>
</Toolbar>
</AppBar>
);
};

export default Navbar;
