import React from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';
import image12 from '../assets/image12.png';
import image13 from '../assets/image13.png';
import image14 from '../assets/image14.png';
import image15 from '../assets/image15.png';
import image16 from '../assets/image16.png';
import image17 from '../assets/image17.png';
import image20 from '../assets/image20.png';
import image44 from '../assets/image44.png';

// Main About Us Page Component
const AboutUsPage = () => {
  const teamMembers = [
    {
      image: image13,
      title: 'Product Manager',
      description: 'The Product Manager shapes NumeroAlpha’s growth by defining the product roadmap. They align business goals with customer needs through market research. Collaborating with teams, they prioritize features for development, ensuring that each release adds value and keeps the platform competitive.',
    },
    {
      image: image12,
      title: 'Architect',
      description: 'The Architect leads the technical design using Microservice Architecture. They ensure that NumeroAlpha’s platform is scalable, secure, and efficient by integrating backend (Java, Spring Boot) and frontend (ReactJS) technologies, while continuously evaluating new tech to maintain flexibility.',
    },
    {
      image: image14,
      title: 'UI Frontend Developer',
      description: 'The UI Developer brings design ideas to life, creating responsive interfaces using ReactJS. They work with UX designers to optimize navigation and load times, ensuring NumeroAlpha’s platform is intuitive and user-friendly.',
    },
    {
      image: image15,
      title: 'UX Designer',
      description: 'The UX Designer focuses on user-centric designs. Through research, they craft workflows and prototypes in Figma, refining them based on feedback to enhance usability and accessibility on NumeroAlpha’s platform.',
    },
    {
      image: image16,
      title: 'Backend Developer',
      description: 'The Backend Developer manages server logic, databases, and APIs using Java and Spring Boot. They ensure reliable performance, security, and system integration with the frontend, supporting smooth operations for payroll management.',
    },
    {
      image: image17,
      title: 'Marketing and Sales',
      description: 'This team promotes NumeroAlpha by crafting marketing strategies that drive brand awareness and customer engagement. Through digital marketing and events, they build relationships and generate leads to support company growth.',
    },
  ];

  return (
    <Box>
      {/* Hero Section with Background Image */}
      <Box
        sx={{
          width: '100%',
          height: '60vh',
          backgroundImage: `url(${image20})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Typography variant="h1" sx={{ fontSize: '4rem', fontWeight: 'bold', color: '#ffff'}}>
          Who Are We?
        </Typography>
      </Box>

      {/* About Us Content */}
      <Box sx={{ padding: '25px', backgroundColor: '#ffff', textAlign: 'center' }}>
        <Typography sx={{ fontSize: '1.5rem', fontWeight: 'bold', color: '#000', marginBottom: '20px' }}>
          Welcome to NumeroAlpha!
        </Typography>

        <Typography sx={{ fontSize: '1.2rem', color: '#555', marginBottom: '20px' }}>
        At NumeroAlpha, we transform payroll management with our advanced platform, designed for businesses of all sizes. Our solution combines cutting-edge technology to ensure precise and efficient payroll calculations, delivering accuracy and value.
        </Typography>

        {/* Mission and Vision */}
        <Box
            component="img"
            src={image44} 
            alt="Image between sections"
            sx={{
                    width: '100%',  
                    height: 'auto', 
                    margin: '20px auto',  
                    display: 'block', 
                }}
        />


        <Typography sx={{ fontSize: '1.5rem', fontWeight: 'bold', color: '#000', marginBottom: '20px' }}>
        Our Team
        </Typography>
        <Typography sx={{ fontSize: '1.2rem', color: '#555', marginBottom: '20px' }}>
        Our team is composed of industry experts and innovators who are dedicated to driving excellence in payroll management. From visionary product managers and technical architects to creative UX designers and dynamic marketing professionals, each member of our team contributes to making NumeroAlpha a leader in the payroll space.
        </Typography>

        <Typography sx={{ fontSize: '1.5rem', fontWeight: 'bold', color: '#000', marginBottom: '20px',textAlign: 'center' }}>
        What We Do?
        </Typography>
        <Typography sx={{ fontSize: '1.2rem', color: '#555', marginBottom: '20px',textAlign: 'center' }}>
        NumeroAlpha simplifies payroll management with intuitive tools and customer-focused support. Our platform automates tasks, provides real-time insights, and delivers an efficient experience, ensuring even the most complex processes are easy to manage.           
        </Typography>

       
      </Box>

      {/* Leadership Section - Team Members */}
      <Box sx={{ padding: '50px 25px 50px 25px', backgroundColor: '#f3f4f6' }}>
      <Typography variant="h6" sx={{ fontSize: '1.8rem', fontWeight: 'bold', color: '#555', textAlign: 'center' }}>
          Leadership and Contributions at NumeroAlpha
        </Typography>
        <Grid container spacing={3} sx={{ marginTop: '5px', padding: '1.5rem' }}>
          {teamMembers.map((member, index) => (
            <Grid item xs={12} sm={6} md={4} key={index} sx={{ marginBottom: '20px', position: 'relative' }}>
              <Paper elevation={3} sx={{ height: '100%', borderRadius: '15px', overflow: 'hidden', position: 'relative', transition: 'transform 0.3s ease-in-out', '&:hover': { transform: 'scale(1.05)' } }}>
                
                {/* Image */}
                <Box
                  component="img"
                  src={member.image}
                  alt={member.title}
                  sx={{
                    width: '100%',
                    height: '250px',
                    objectFit: 'cover',
                  }}
                />
                
                {/* Title and Description */}
                <Typography
                  variant="h5"
                  sx={{
                    marginTop: '10px',
                    fontSize: '1.5rem',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: '#333',
                  }}
                >
                  {member.title}
                </Typography>
                <Box sx={{ padding: '20px' }}>
                  <Typography sx={{ fontSize: '1rem', color: '#555', textAlign: 'center' }}>
                    {member.description}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default AboutUsPage;
