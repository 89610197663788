import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { FaXTwitter, FaInstagram, FaYoutube, FaLinkedin } from 'react-icons/fa6';

const Footer = () => {
  return (
    <Box 
      component="footer" 
      sx={{ 
        width: '100%', 
        padding: '20px', 
        backgroundColor: '#fff', 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        boxSizing: 'border-box' 
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <IconButton
          component="a"
          href="https://x.com/numeroalphaa"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ color: '#000', marginRight: '15px' }}
        >
          <FaXTwitter />
        </IconButton>
        <IconButton
          component="a"
          href="https://www.instagram.com/numeroalphaa"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ color: '#000', marginRight: '15px' }}
        >
          <FaInstagram />
        </IconButton>
        <IconButton
          component="a"
          href="https://www.youtube.com/@NumeroAlpha"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ color: '#000', marginRight: '15px'}}
        >
          <FaYoutube />
        </IconButton>
        <IconButton
          component="a"
          href="https://www.linkedin.com/company/numeroalphaa/"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ color: '#000'}}
        >
          <FaLinkedin />
        </IconButton>
      </Box>
      <Typography 
        sx={{ 
          fontSize: '0.95rem', 
          fontWeight: 500, 
          color: '#000' 
        }}
      >
        &copy; {new Date().getFullYear()} NumeroAlpha LLP
      </Typography>
    </Box>
  );
};

export default Footer;
