import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Grid } from '@mui/material';
import articles from '../data/articles'; 

const ArticleDetailPage = () => {
  const { id } = useParams();  
  const article = articles.find((art) => art.id === id);  
  const [firstPart, setFirstPart] = useState(''); 
  const [secondPart, setSecondPart] = useState('');  
  const imgRef = useRef(null); 

  useEffect(() => {
    if (article && article.fullContent) {
     
      const splitContent = () => {
        const imageHeight = imgRef.current ? imgRef.current.offsetHeight : 300; 
        const averageCharHeight = 34;  
        const charactersPerLine = 78; 
        const linesBesideImage = Math.floor(imageHeight / averageCharHeight);  
        const charsToFitBesideImage = linesBesideImage * charactersPerLine; 

        // Split the content based on sentences
        const sentences = article.fullContent.match(/[^.!?]+[.!?]+/g) || [];

        let charCount = 0;
        let firstHalf = '';

        // Loop through the sentences until they fit beside the image
        for (let sentence of sentences) {
          charCount += sentence.length;
          if (charCount <= charsToFitBesideImage) {
            firstHalf += sentence;
          } else {
            break;
          }
        }

        // Join the remaining sentences for the second part
        const secondHalf = article.fullContent.replace(firstHalf, '');

        setFirstPart(firstHalf);
        setSecondPart(secondHalf);
      };

      splitContent();
      window.scrollTo(0, 0);  

      // Add resize event listener to handle window resizing
      window.addEventListener('resize', splitContent);

      // Cleanup the event listener on component unmount
      return () => window.removeEventListener('resize', splitContent);
    }
  }, [article]);

  if (!article) {
    return <Typography variant="h4">Article not found</Typography>;
  }

  return (
    <Box sx={{ padding: '20px', backgroundColor: '#f8f9fa' }}>
      <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '20px', marginTop: '30px' }}>
        {article.title}
      </Typography>

      {/* Grid layout */}
      <Grid container spacing={2}>
        {/* Image taking 40% width */}
        <Grid item xs={12} md={5}>
          <Box
            component="img"
            ref={imgRef} // Reference to the image
            src={article.image}
            alt={article.title}
            sx={{
              width: '100%',
              height: 'auto',
              borderRadius: '10px',
              objectFit: 'contain',
              marginTop:'25px',
            }}
          />
        </Grid>

        {/* Article content beside the image */}
        <Grid item xs={12} md={7}>
          <Typography variant="body1" sx={{ fontSize: '1.2rem' }}>
            <Box dangerouslySetInnerHTML={{ __html: firstPart }} />
          </Typography>
        </Grid>
      </Grid>

      {/* Full-width article content after the image */}
      <Grid item xs={12} >
        <Typography variant="body1" sx={{ fontSize: '1.2rem' }}>
          <Box dangerouslySetInnerHTML={{ __html: secondPart }} />
        </Typography>
      </Grid>
    </Box>
  );
};

export default ArticleDetailPage;
