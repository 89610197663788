import React, { useState } from 'react';
import { Box, Typography, Button, MenuItem, FormControl, Select, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { Link } from 'react-router-dom';
import { useMediaQuery } from '@mui/material'; // Import useMediaQuery
import image1 from '../assets/image1.png';
import image4 from '../assets/image4.png';
import image5 from '../assets/image5.png';
import image6 from '../assets/image6.png';

const careers = [
  {
    id: 1,
    title: 'UI Frontend Developer',
    description: 'As a UI Frontend Developer at NumeroAlpha, you will be responsible for developing visually appealing and user-friendly interfaces.',
    location: 'Bengaluru, India',
    department: 'Engineering',
    image: image1,
  },
  {
    id: 2,
    title: 'Backend Developer',
    description: 'As a Backend Developer at NumeroAlpha, you will be responsible for developing and maintaining the server-side logic.',
    location: 'Bengaluru, India',
    department: 'Engineering',
    image: image4,
  },
  {
    id: 3,
    title: 'UX Designer',
    description: 'As a UX Designer at NumeroAlpha, you will be responsible for creating intuitive and user-centric designs that enhance the overall experience.',
    location: 'Bengaluru, India',
    department: 'User Experience',
    image: image5,
  },
  {
    id: 4,
    title: 'Marketing and Sales Specialist',
    description: 'As a Marketing and Sales Specialist at NumeroAlpha, you will be responsible for driving brand awareness, generating leads, and converting prospects.',
    location: 'Bengaluru, India',
    department: 'Marketing',
    image: image6,
  },
];

const CareersPage = () => {
  const [selectedLocation, setSelectedLocation] = useState('All Locations');
  const [selectedRole, setSelectedRole] = useState('All Roles');

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm')); // Media query for mobile screens

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  // Filter careers based on selected location and role
  const filteredCareers = careers.filter((career) => {
    const locationMatch = selectedLocation === 'All Locations' || career.location.includes(selectedLocation);
    const roleMatch = selectedRole === 'All Roles' || career.title === selectedRole;
    return locationMatch && roleMatch;
  });

  return (
   <Box sx={{ fontFamily: 'Tenorite, sans-serif', padding: '25px' }}>

      {/* Title Section */}
      <Box sx={{ textAlign: 'left', marginBottom: '40px' }}>
        <Typography variant="h3" sx={{ fontWeight: 'bold', fontSize: '2.5rem', marginBottom: '10px' }}>
          We are Hiring
        </Typography>
        <Typography variant="subtitle1" sx={{ fontSize: '1.25rem', marginBottom: '40px' }}>
          We are OK with no experience
        </Typography>
      </Box>

      {/* Centered Dropdown for Location and Roles */}
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '40px' }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
          Opening Positions
        </Typography>

        <Box sx={{ display: 'flex', gap: '20px', justifyContent: 'center' }}>
          {/* Location Dropdown */}
          <FormControl sx={{ minWidth: 200, borderRadius: '25px' }}>
            <Select
              value={selectedLocation}
              onChange={handleLocationChange}
              displayEmpty
              sx={{ borderRadius: '25px' }}
            >
              <MenuItem value="All Locations">All Locations</MenuItem>
              <MenuItem value="India">India</MenuItem>
              <MenuItem value="USA">USA</MenuItem>
            </Select>
          </FormControl>

          {/* Roles Dropdown */}
          <FormControl sx={{ minWidth: 200, borderRadius: '25px' }}>
            <Select
              value={selectedRole}
              onChange={handleRoleChange}
              displayEmpty
              sx={{ borderRadius: '25px' }}
            >
              <MenuItem value="All Roles">All Roles</MenuItem>
              {careers.map((career) => (
                <MenuItem key={career.id} value={career.title}>
                  {career.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>

      {/* Career list in Table layout */}
      <Table>
        <TableBody>
          {filteredCareers.length ? (
            filteredCareers.map((career, index) => (
              <TableRow key={index}>
                <TableCell>
                  {/* Title */}
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    {career.title}
                  </Typography>

                  {/* Department - show below title on mobile */}
                  {isMobile && (
                    <Typography variant="body1" color="textSecondary">
                      {career.department}
                    </Typography>
                  )}
                </TableCell>

                {/* Department - show on desktop */}
                {!isMobile && (
                  <TableCell>
                    <Typography variant="body1" color="textSecondary">
                      {career.department}
                    </Typography>
                  </TableCell>
                )}

                <TableCell>
                  <Typography variant="body1" color="textSecondary">
                    {career.location}
                  </Typography>
                </TableCell>

                <TableCell align="right">
                  <Link to={`/careers/${career.id}`} style={{ textDecoration: 'none' }}>
                    <Button variant="contained" color="primary">
                      Details
                    </Button>
                  </Link>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <Typography variant="h6" sx={{ color: '#888', margin: '20px' }}>
              No roles available for the selected filters.
            </Typography>
          )}
        </TableBody>
      </Table>
    </Box>
  );
};

export default CareersPage;
