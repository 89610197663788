import React from 'react';
import { FaAccusoft } from 'react-icons/fa';
import { Grid, Typography, Box, Paper } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import image7 from '../assets/image7.png';
import image18 from '../assets/image18.jpg';
import image31 from '../assets/image31.png';
import image32 from '../assets/image32.png';
import image33 from '../assets/image33.png';
import image34 from '../assets/image34.png';
import image35 from '../assets/image35.png';
import image36 from '../assets/image36.png';
import image37 from '../assets/image37.png';
import image38 from '../assets/image38.png';
import image39 from '../assets/image39.png';


const HomePage = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.state?.targetSection) {
      const section = document.getElementById(location.state.targetSection);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);
  const features = [
    {
      title: "Employee Data Management",
      description: "Streamline your employee information with our comprehensive Employee Master Data Management system. This solution centralizes and organizes employee records, providing seamless access and efficient management. With a robust framework in place, you can easily maintain up-to-date information, ensuring accuracy and reducing administrative overhead.",
    },
    {
      title: "Payroll US",
      description: "Our Payroll US solution simplifies and automates all aspects of payroll processing. Designed to handle the intricacies of US payroll with precision, it ensures accurate calculations and compliance with federal and state regulations. From wage computations to tax deductions, our system provides a reliable and efficient payroll experience, helping you stay compliant and focused on your business.",
    },
    {
      title: "Payroll India",
      description: "Managing payroll in India can be complex, but our dedicated solution simplifies the process. Automate payroll calculations, including taxes and deductions, with our advanced system tailored to meet local regulations and compliance requirements. Our Payroll India solution ensures accurate and timely processing, reducing the complexity and administrative burden associated with Indian payroll management.",
    },
    {
      title: "Posting to Finance",
      description: "Optimize your financial operations with our automated posting to finance feature. This solution integrates payroll data directly into your financial software, streamlining the process and reducing manual data entry. By minimizing errors and ensuring seamless data transfer, you can enhance the accuracy of your financial reporting and focus on strategic financial management.",
    },
    {
      title: "Bank Transfer",
      description: "Effortlessly manage salary disbursements with our secure and efficient bank transfer capabilities. Our solution ensures timely transfers of salaries directly to employees' bank accounts, with the option for bulk payments to save time and effort. Simplify your payroll disbursement process and enhance operational efficiency with our reliable bank transfer feature.",
    },
    {
      title: "US Benefits",
      description: "In the United States, employee benefits are a critical component of compensation packages, impacting employee satisfaction and retention. Our US Benefits solution encompasses a range of benefits, including 401(k) plans, health insurance, and more. By offering a comprehensive benefits package, you can enhance employee well-being and strengthen your organization's overall compensation strategy.",
    }
  ];

  return (
      <Box>
        {/* Hero Section */}
        <Box id="hero-section" sx={{
          width: '100%',
          height: '60vh',
          backgroundImage: `url(${image7})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          color: '#D9D9D9',
        }}>
          <Box>
            <Typography variant="h1" sx={{ fontSize: '4rem', fontWeight: 'bold', color: '#FFFFFF' }}>
              Simplifying Payroll
            </Typography>
            <Typography variant="h1" sx={{ fontSize: '4rem', fontWeight: 'bold', color: '#FFFFFF' }}>
              Making it Simple
            </Typography>
          </Box>
        </Box>

        {/* Features Section */}
        <Box sx={{ padding: '50px 15px', backgroundColor: '#f8f9fa' }}>
          <Box sx={{ textAlign: 'left', marginBottom: '30px' }}>
            <Typography variant="h3" sx={{fontWeight: 'bold', fontSize: '2rem', marginBottom: '10px' }}>Cloud Payroll</Typography>
            <Typography sx={{ fontSize: '1rem', color: '#6c757d' }}>Accurate Pay Calculation with Best Customer experience and service</Typography>
          </Box>
          <Grid container spacing={4} sx={{ marginBottom: '30px' }}>
            {features.map((feature, index) => (
              <Grid item xs={12} sm={6} md={4} key={index} sx={{ marginBottom: '30px',  }}>
                <Paper elevation={3} sx={{ padding: '20px', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', borderRadius: '10px', transition: 'box-shadow 0.3s ease-in-out', '&:hover': { boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)' } }}>
                  <Box>
                    <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px', color: '#333' }}>
                      <FaAccusoft style={{ marginRight: '8px' }} />
                      {feature.title}
                    </Typography>
                    <Typography sx={{ fontSize: '1rem', color: '#6c757d' }}>{feature.description}</Typography>
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Next Section */}
        <Box id="next-section" sx={{
          position: 'relative',
          padding: '50px 20px',
          height: '60vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          backgroundImage: `url(${image18})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          border: '0.8px solid #ccc',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundImage: `url(${image18})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            opacity: 0.8,
            zIndex: 1,
          },
        }}>
          <Box sx={{ position: 'relative', zIndex: 2 }}>
            <Typography variant="h1" sx={{ fontSize: '4rem', fontWeight: 'bold', color: '#333333' }}>
              Cloud Payroll with
            </Typography>
            <Typography variant="h1" sx={{ fontSize: '4rem', fontWeight: 'bold', color: '#333333' }}>
              Microservice Architecture
            </Typography>
          </Box>
        </Box>

        {/* Content Section */}
        <Box sx={{ padding: '50px 15px', backgroundColor: '#f8f9fa' }}>
  <Grid container spacing={3}>
    {[image31, image32, image33, image34, image35, image36, image37, image38, image39].map((image, index) => (
      <Grid item xs={6} sm={6} md={4} key={index}>
        <Box
          component="img"
          src={image}
          alt={`Image ${index + 31}`}
          sx={{ 
            width: '100%',
            height: 'auto',
            borderRadius: '5px',
            objectFit: 'cover',
            transition: 'box-shadow 0.3s ease-in-out', '&:hover': { boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',}
          }}
        />
      </Grid>
    ))}
  </Grid>
</Box>

</Box>
        );
};

export default HomePage;