import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Button, Grid, Card, CardContent, IconButton } from '@mui/material';
import { LocationOn, Work } from '@mui/icons-material';
import { FaXTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import jobs from '../data/jobs'; 
import { useMediaQuery } from '@mui/material';  // Add this import

const JobDescriptionPage = () => {
  const { id } = useParams(); 
  const jobId = parseInt(id); 
  const job = jobs.find((job) => job.id === jobId); 
  const navigate = useNavigate();

  // Media query to check if the screen size is mobile (e.g., max-width: 600px)
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  if (!job) {
    return <Typography variant="h4">Job not found</Typography>;
  }

  return (
    <Box sx={{ padding: '40px', backgroundColor: '#f8f9fa' }}>
      <Grid container spacing={3}>

        {/* Main job description section */}
        <Grid item xs={12} md={8}>
          <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '40px' }}>
            {job.title}
          </Typography>

          {/* Add mobile-only sidebar below the job title */}
          {isMobile && (
            <Card elevation={3} sx={{ padding: '20px', textAlign: 'center', marginBottom: '20px' }}>
              <CardContent>
                {/* Department */}
                <Box display="flex" alignItems="center" sx={{ marginBottom: '10px' }}>
                  <Work sx={{ marginRight: '10px', color: '#6e6e6e' }} />
                  <Typography variant="body1">{job.department}</Typography>
                </Box>
                {/* Location */}
                <Box display="flex" alignItems="center" sx={{ marginBottom: '20px' }}>
                  <LocationOn sx={{ marginRight: '10px', color: '#6e6e6e' }} />
                  <Typography variant="body1">{job.location}</Typography>
                </Box>

                {/* Apply Now button */}
                <Button 
                  variant="contained" 
                  color="primary" 
                  fullWidth sx={{ marginBottom: '20px' }}
                  onClick={() => navigate(`/careers/apply/${job.title}`)}>
                  Apply now
                </Button>

                {/* Social media sharing */}
                <Typography variant="body2" color="textSecondary" sx={{ marginBottom: '10px' }}>
                  Share on:
                </Typography>

                <Box display="flex" justifyContent="center" gap={2}>
                  <IconButton
                    component="a"
                    href="https://x.com/numeroalphaa"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ color: '#000' }}
                  >
                  <FaXTwitter />
                  </IconButton>
                  <IconButton
                    component="a"
                    href="https://www.instagram.com/numeroalphaa"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ color: '#000' }}
                  >
                  <FaInstagram />
                  </IconButton>
                  <IconButton
                    component="a"
                    href="https://www.linkedin.com/company/numeroalphaa/"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ color: '#000'}}
                  >
                  <FaLinkedin />
                  </IconButton>
                </Box>
              </CardContent>
            </Card>
          )}

          {/* Job overview and details */}
          <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
            Job Overview:
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '20px' }}>
            {job.overview}
          </Typography>

          {/* Responsibilities */}
          <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
            Key Responsibilities:
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '20px' }}>
            <ul>
              {job.responsibilities.map((resp, index) => (
                <li key={index}>{resp}</li>
              ))}
            </ul>
          </Typography>

          {/* Qualifications */}
          <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
            Qualifications:
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '20px' }}>
            <ul>
              {job.qualifications.map((qual, index) => (
                <li key={index}>{qual}</li>
              ))}
            </ul>
          </Typography>

          {/* Second apply now button */}
          <Button 
            variant="contained" 
            color="primary" 
            size="large" 
            sx={{ marginTop: '20px' }}
            onClick={() => navigate(`/careers/apply/${job.title}`)} >
            Apply now
          </Button>
        </Grid>

        {/* Right side bar - desktop only */}
        {!isMobile && (
          <Grid item xs={12} md={4}>
            <Card elevation={3} sx={{ padding: '20px', textAlign: 'center' }}>
              <CardContent>
                {/* Department */}
                <Box display="flex" alignItems="center" sx={{ marginBottom: '10px' }}>
                  <Work sx={{ marginRight: '10px', color: '#6e6e6e' }} />
                  <Typography variant="body1">{job.department}</Typography>
                </Box>
                {/* Location */}
                <Box display="flex" alignItems="center" sx={{ marginBottom: '20px' }}>
                  <LocationOn sx={{ marginRight: '10px', color: '#6e6e6e' }} />
                  <Typography variant="body1">{job.location}</Typography>
                </Box>

                {/* Apply Now button */}
                <Button 
                  variant="contained" 
                  color="primary" 
                  fullWidth sx={{ marginBottom: '20px' }}
                  onClick={() => navigate(`/careers/apply/${job.title}`)}>
                  Apply now
                </Button>

                {/* Social media sharing */}
                <Typography variant="body2" color="textSecondary" sx={{ marginBottom: '10px' }}>
                  Share on:
                </Typography>

                <Box display="flex" justifyContent="center" gap={2}>
                  <IconButton
                    component="a"
                    href="https://x.com/numeroalphaa"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ color: '#000' }}
                  >
                  <FaXTwitter />
                  </IconButton>
                  <IconButton
                    component="a"
                    href="https://www.instagram.com/numeroalphaa"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ color: '#000' }}
                  >
                  <FaInstagram />
                  </IconButton>
                  <IconButton
                    component="a"
                    href="https://www.linkedin.com/company/numeroalphaa/"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ color: '#000'}}
                  >
                  <FaLinkedin />
                  </IconButton>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default JobDescriptionPage;
