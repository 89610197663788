// src/pages/ResourcesPage.js

import React from 'react';
import { Box, Typography, Paper, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';  // Import useNavigate for navigation
import articles from '../data/articles';  // Import articles data
import image19 from '../assets/image19.png';


const ResourcesPage = () => {
  const navigate = useNavigate();

  return (
    <Box>
      {/* Hero Section */}
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '60vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          overflow: 'hidden',
          backgroundImage: `url(${image19})`,
          backgroundSize: 'cover',
          backgroundPosition: 'bottom',
          opacity: 0.8,
        }}
      >
        <Typography
          variant="h1"
          sx={{ fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem', lg: '4rem' }, fontWeight: 'bold', color: '#ffffff' }}
        >
          Knowledge Hub
        </Typography>
      </Box>

      {/* Articles Section */}
      <Box sx={{ padding: '1.5rem' }}>
        <Typography variant="h2" sx={{ fontWeight: 'bold', fontSize: '2rem', color: 'black', marginBottom: '20px', marginTop: '30px' }}>
          Trending Articles
        </Typography>

        {articles.map((article) => (
          <Paper
            key={article.id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: '20px',
              backgroundColor: '#f9f9f9',
              border: '1px solid #e5e5e5',
              borderRadius: '10px',
              marginBottom: '20px',
              flexDirection: { xs: 'column', sm: 'row' },
              transition: 'box-shadow 0.3s ease-in-out',
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
              '&:hover': { boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)' }
            }}
          >
            <Box
              component="img"
              src={article.image}
              alt={article.title}
              sx={{
                width: { xs: '100%', sm: '150px' },
                height: { xs: 'auto', sm: '150px' },
                borderRadius: '10px',
                marginBottom: { xs: '15px', sm: '0' },
                marginRight: { sm: '20px' },
              }}
            />
            <Box sx={{ textAlign: 'left' }}>
              <Typography variant="h3" sx={{ fontWeight: 'bold', fontSize: '1.5rem', marginBottom: '10px', color: 'black' }}>
                {article.title}
              </Typography>
              <Typography variant="body1" sx={{ fontSize: '1rem', color: '#555', marginBottom: '10px' }}>
                {article.description}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate(`/resources/${article.id}`)}  
                sx={{
                  textTransform: 'none',
                  padding: '5px 25px',
                  borderRadius: '5px',
                  backgroundColor: '#007bff',
                  '&:hover': {
                    backgroundColor: '#0056b3',
                  },
                }}
              >
                Details
              </Button>
            </Box>
          </Paper>
        ))}
      </Box>
    </Box>
  );
};

export default ResourcesPage;
