import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ProductsPage from './pages/ProductsPage';
import CareersPage from './pages/CareersPage';
import ResourcesPage from './pages/ResourcesPage';
import AboutUsPage from './pages/AboutUsPage';
import ContactPage from './pages/ContactPage';
import theme from './theme/customTheme';
import Layout from './components/Layout';
import ArticleDetailPage from './pages/ArticleDetailPage';
import JobDescriptionPage from './pages/JobDescriptionPage';
import JobApplicationPage from './pages/JobApplicationPage';
import { ThemeProvider } from '@mui/material/styles';


function App() {
  return (
    <ThemeProvider theme={theme}>
    <Router>
      <Layout>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/products" element={<ProductsPage />} />
        <Route path="/careers" element={<CareersPage/>} />
        <Route path="/careers/:id" element={<JobDescriptionPage />} />
        <Route path="/careers/apply/:title" element={<JobApplicationPage />} />
        <Route path="/about" element={<AboutUsPage />} />
        <Route path="/resources" element={<ResourcesPage/>} />
        <Route path="/resources/:id" element={<ArticleDetailPage />} />  
        <Route path="/contact" element={<ContactPage />} /> 

        {/* Add more routes here if needed */}
      </Routes>
      </Layout>
    </Router>
    </ThemeProvider>

  );
}

export default App;
