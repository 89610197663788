import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { Box, Typography, TextField, Button, Paper, Dialog, DialogActions, DialogContent, DialogContentText, Grid, } from '@mui/material';

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.name || !formData.email || !formData.phone) {
      alert('Please fill out all required fields.');
      return;
    }

    // EmailJS send function
    emailjs.send(
      'service_d4d75ra', // EmailJS service ID
      'template_qsg3tz5', // EmailJS template ID
      {
        from_name: formData.name,
        from_email: formData.email,
        from_phone: formData.phone,
        message: formData.message,
        emailMessage: "You have received a new message from your website contact form:",
      },
      'B3-lfoV6RI3OFp5gM' // EmailJS User ID
    )
    .then((result) => {
      console.log(result.text);
      setDialogOpen(true);  // Open the dialog box on success
    }, (error) => {
      console.log(error.text);
      alert('Failed to send the message, please try again.');
    });

    setFormData({ name: '', email: '', phone: '', message: '' }); 
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
        <Box
        sx={{
          height: '40vh',
          backgroundColor: 'black',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        >
      <Typography variant="h2" sx={{ color: '#fff', fontWeight: 'bold' }}>
       Contact Us
     </Typography>
    </Box>
    <Box sx={{ padding: '40px 20px 0 20px',backgroundColor: '#f8f9ff', minHeight: '70vh' }}>
      <Grid container spacing={4} sx={{ minHeight: '70vh' }}>
        {/* Left Side Content */}
        <Grid item xs={12} md={7}>
          <Typography variant="h5" sx={{ marginBottom: '20px', color: '#2196F3' }}>
            We’d love to hear from you.
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '30px', color: '#555' }}>
            At NumeroAlpha, we are committed to providing exceptional service and support. Whether you have questions about our products, need assistance, or want to explore partnership opportunities, our team is here to help. Please use the following contact details to reach out to us.
          </Typography>
         
          <Typography variant="h6" sx={{ marginBottom: '5px', color: '#2196F3' }}>
            General Inquiries:
          </Typography>
          <Typography variant="body2" sx={{ marginBottom: '10px', color: '#555' }}>
          For general information about our products and services, please email us at:
          </Typography>
          <Typography variant="body2" sx={{fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
            Email: contact@numeroalpha.com
          </Typography>
          <Typography variant="body2" sx={{fontWeight: 'bold', marginBottom: '30px', color: '#555' }}>
            Phone: +91 879 250 5946
          </Typography>

          <Typography variant="h6" sx={{ marginBottom: '5px', color: '#2196F3' }}>
            Sales and Partnerships:
          </Typography>
          <Typography variant="body2" sx={{ marginBottom: '10px', color: '#555' }}>
          Interested in learning more about how NumeroAlpha can benefit your organization? <br />
          For sales inquiries or partnership opportunities, please reach out to:
          </Typography>
          <Typography variant="body2" sx={{fontWeight: 'bold', marginBottom: '10px', color: '#555' }}>
            Email: contact@numeroalpha.com
          </Typography>
          <Typography variant="body2" sx={{fontWeight: 'bold', marginBottom: '30px', color: '#555' }}>
            Phone: +91 879 250 5946
          </Typography>

          <Typography variant="h6" sx={{ marginBottom: '10px', color: '#2196F3' }}>
            Headquarters,
          </Typography>
          <Typography variant="body2" sx={{fontWeight: 'bold', color: '#555' }}>
            NumeroAlpha LLP<br />
            No 633, 2nd floor,
            16th A Main Road,<br />
            Doddakallasandra,
            Bangalore - 62
          </Typography>

        </Grid>

        {/* Right Side Contact Form */}
        <Grid item xs={12} md={5}>
          <Box className="contact-form-container" sx={{ width: '100%', maxWidth: '400px', marginLeft: { md: 'auto' } }}>
            <Typography variant="h5" sx={{ fontSize: 33, fontWeight: 'bold', marginTop: '5px', marginBottom: '10px', color: '#2196F3' }}>
              Send us a message.
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '20px', color: '#555' }}>
              For any other inquiries or feedback, feel free to fill out the contact form below, and a member of our team will get back to you promptly:
            </Typography>
            <Paper
              className="contact-form"
              sx={{
                backgroundColor: '#fff',
                padding: '20px',
                borderRadius: '8px',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                width: '100%',
                maxWidth: '450px',
                boxSizing: 'border-box',
              }}
            >
              <form onSubmit={handleSubmit}>
                <Box className="form-group" sx={{ marginBottom: '15px', textAlign: 'left' }}>
                  <Typography component="label" htmlFor="name" sx={{ display: 'block', marginBottom: '5px', fontWeight: 500, color: '#333' }}>
                    Name
                  </Typography>
                  <TextField
                    id="name"
                    name="name"
                    variant="outlined"
                    value={formData.name}
                    onChange={handleChange}
                    fullWidth
                    required
                    sx={{ marginBottom: '5px' }}
                  />
                </Box>
                <Box className="form-group" sx={{ marginBottom: '15px', textAlign: 'left' }}>
                  <Typography component="label" htmlFor="email" sx={{ display: 'block', marginBottom: '5px', fontWeight: 500, color: '#333' }}>
                    Email
                  </Typography>
                  <TextField
                    id="email"
                    name="email"
                    type="email"
                    variant="outlined"
                    value={formData.email}
                    onChange={handleChange}
                    fullWidth
                    required
                    sx={{ marginBottom: '5px' }}
                  />
                </Box>
                <Box className="form-group" sx={{ marginBottom: '15px', textAlign: 'left' }}>
                  <Typography component="label" htmlFor="phone" sx={{ display: 'block', marginBottom: '5px', fontWeight: 500, color: '#333' }}>
                    Phone
                  </Typography>
                  <TextField
                    id="phone"
                    name="phone"
                    variant="outlined"
                    value={formData.phone}
                    onChange={handleChange}
                    fullWidth
                    required
                    sx={{ marginBottom: '5px' }}
                  />
                </Box>
                <Box className="form-group" sx={{ marginBottom: '15px', textAlign: 'left' }}>
                  <Typography component="label" htmlFor="message" sx={{ display: 'block', marginBottom: '5px', fontWeight: 500, color: '#333' }}>
                  Enquiry Summary
                  </Typography>
                  <TextField
                    id="message"
                    name="message"
                    variant="outlined"
                    multiline
                    rows={2}
                    placeholder="(optional)"
                    value={formData.message}
                    onChange={handleChange}
                    fullWidth
                    sx={{ marginBottom: '5px' }}
                  />
                </Box>
                <Button
                  type="submit"
                  fullWidth
                  sx={{
                    backgroundColor: '#000',
                    color: '#fff',
                    borderRadius: '4px',
                    fontSize: '1rem',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#333',
                    },
                  }}
                >
                  Submit
                </Button>
              </form>
            </Paper>
          </Box>
        </Grid>
      </Grid>

      {/* Dialog box for success message */}
      <Dialog
  open={dialogOpen}
  onClose={handleDialogClose}
  PaperProps={{
    sx: {
      background: 'linear-gradient(135deg, #f8f9fa, #d1d5db)',
      borderRadius: '20px',
      boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.1)',
      padding: '20px',
      maxWidth: '600px', // wider than default for a modern look
    }
  }}
>
  <DialogContent>
    <DialogContentText 
      sx={{ 
        textAlign: 'center', 
        color: '#333', 
        fontSize: '1.1rem' 
      }}
    >
      <Typography 
        variant="h6" 
        sx={{ 
          marginBottom: '20px', 
          fontWeight: '600', 
          fontSize: '1.4rem', 
          lineHeight: '1.5',
          color: '#1a202c'
        }}
      >
        Message Sent
      </Typography>
      <Typography 
        sx={{ 
          fontSize: '1.2rem', 
          lineHeight: '1.6',
          color: '#4a5568'
        }}
      >
            We look forward to connecting with you and addressing any questions or concerns you may have. Thank you for choosing NumeroAlpha!
      </Typography>
    </DialogContentText>
  </DialogContent>
  <DialogActions sx={{ justifyContent: 'center' }}>
    <Button 
      onClick={handleDialogClose} 
      sx={{ 
        background: 'linear-gradient(135deg, #4a90e2, #0062cc)', 
        color: '#fff',
        fontWeight: 'bold',
        padding: '10px 30px',
        borderRadius: '50px',
        textTransform: 'none',
        transition: 'transform 0.3s ease',
        '&:hover': {
          background: 'linear-gradient(135deg, #3a7bd5, #2859a6)',
          transform: 'scale(1.05)',  // slight zoom on hover
        }
      }}
    >
      Close
    </Button>
  </DialogActions>
</Dialog>

      <Typography variant="h6" sx={{ color: '#000' }}>
            Follow us:
      </Typography>
    </Box>
    </>
  );
};

export default ContactPage;