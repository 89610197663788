const jobs = [
    {
      id: 1,
      title: 'UI Frontend Developer',
      overview:
        'As a UI Frontend Developer at NumeroAlpha, you will be responsible for developing visually appealing and user-friendly interfaces that provide an exceptional user experience. You will work closely with the design and backend teams to implement features that are both functional and aesthetically pleasing.',
      responsibilities: [
        'Develop user-facing features using HTML, CSS, JavaScript, and React.',
        'Translate design mockups and wireframes into responsive, high-quality web pages.',
        'Ensure the technical feasibility of UI/UX designs.',
        'Collaborate with backend developers to integrate frontend components with server-side logic.',
        'Optimize applications for maximum speed and scalability.',
        'Conduct testing and debugging to ensure a seamless user experience across different browsers and devices.',
        'Stay up-to-date with the latest industry trends and technologies.',
      ],
      qualifications: [
        'Bachelor’s degree in Computer Science, Web Development, or related field.',
        'Proficiency in HTML, CSS, JavaScript, and React.',
        'Strong understanding of responsive design principles and web development best practices.',
        'Experience with frontend build tools (e.g., Webpack, Babel) and version control systems (e.g., Git).',
        'Ability to work in a collaborative environment and handle multiple projects simultaneously.',
        'Strong attention to detail and a passion for creating high-quality user interfaces.',
      ],
      department: 'Engineering',
      location: 'Bangalore, India',
    },
    {
      id: 2,
      title: 'Backend Developer',
      overview:
        'As a Backend Developer at NumeroAlpha, you will be responsible for developing and maintaining the server-side logic that powers our platform. You’ll collaborate with frontend developers and architects to ensure seamless integration and optimal performance of the platform.',
      responsibilities: [
        'Develop and maintain server-side logic using Java Spring Boot.',
        'Design and implement RESTful APIs for integration with frontend components.',
        'Optimize application performance, scalability, and security.',
        'Collaborate with frontend developers to integrate user-facing elements with backend logic.',
        'Write clean, efficient, and maintainable code.',
        'Conduct code reviews and troubleshoot issues to improve application performance.',
        'Stay updated with the latest backend technologies and best practices.',
      ],
      qualifications: [
        'Bachelor’s degree in Computer Science, Engineering, or related field.',
        'Strong proficiency in Java and Spring Boot framework.',
        'Experience with RESTful APIs, databases (e.g., MySQL, PostgreSQL), and version control systems (e.g., Git).',
        'Knowledge of cloud platforms and containerization (e.g., Docker, Kubernetes) is a plus.',
        'Excellent problem-solving skills and attention to detail.',
        'Ability to work in a collaborative team environment.',
      ],
      department: 'Engineering',
      location: 'Bangalore, India',
    },
    {
      id: 3,
      title: 'UX Designer',
      overview:
        'As a UX Designer at NumeroAlpha, you will be responsible for creating intuitive and user-centric designs that enhance the overall experience of our platform. You’ll collaborate with cross-functional teams to translate user needs and business goals into functional, appealing interfaces.',
      responsibilities: [
        'Conduct user research to understand user needs and pain points.',
        'Create wireframes, prototypes, and high-fidelity mockups.',
        'Collaborate with product managers and developers to ensure designs align with business goals and technical constraints.',
        'Conduct usability testing and analyze feedback to iterate on designs.',
        'Develop and maintain design systems and guidelines.',
        'Ensure that all designs are consistent with NumeroAlpha’s branding and user experience standards.',
        'Stay updated with the latest UX trends and best practices.',
      ],
      qualifications: [
        'Bachelor’s degree in Design, Human-Computer Interaction, or related field.',
        'Strong portfolio showcasing UX design projects.',
        'Proficiency in design tools such as Adobe XD, Sketch, Figma, or similar.',
        'Experience with user research, usability testing, and creating wireframes/prototypes.',
        'Strong communication and collaboration skills.',
        'Ability to work independently and in a team environment.',
        'Knowledge of HTML, CSS, and frontend development is a plus.',
      ],
      department: 'User Experience',
      location: 'Bangalore, India',
    },
    {
      id: 4,
      title: 'Marketing and Sales Specialist',
      overview:
        'As a Marketing and Sales Specialist at NumeroAlpha, you will be responsible for driving brand awareness, generating leads, and converting prospects into customers. You’ll work closely with the product and design teams to create compelling campaigns that resonate with our target audience.',
      responsibilities: [
        'Develop and execute marketing strategies to promote NumeroAlpha’s products and services.',
        'Create and manage content for various channels, including social media, email campaigns, and website.',
        'Conduct market research to identify new opportunities and target audiences.',
        'Develop sales strategies and collaborate with the sales team to meet revenue targets.',
        'Analyze marketing and sales data to measure the effectiveness of campaigns and adjust strategies accordingly.',
        'Build and maintain relationships with customers, partners, and industry influencers.',
        'Stay updated with the latest marketing trends and tools.',
      ],
      qualifications: [
        'Bachelor’s degree in Marketing, Business, or related field.',
        '2+ years of experience in marketing and sales, preferably in the tech or software industry.',
        'Strong understanding of digital marketing strategies and tools.',
        'Excellent communication and interpersonal skills.',
        'Ability to work in a fast-paced, dynamic environment.',
        'Experience with CRM software (e.g., Salesforce) and marketing automation tools (e.g., HubSpot) is a plus.',
        'Strong analytical skills and attention to detail.',
      ],
      department: 'Marketing',
      location: 'Bangalore, India',
    },
  ];
  
  export default jobs;
  